/*

 Directive configuration
 cb/callback - run on intersect
 threshold - intersection threshold. can be fruction (0.30) or string with percet ('30%')
 addClass / class - adds this class
 removeClass  - removes this class
 animate  - add animation class, and sets and removes hidden class automatically
 */

const getObserverConfigFromOptions = (input = {}) => {
  const result = {};

  let targetRatio = 0;

  // implement the "ratio" option

  if (
    // ratio as "30%"
    input.threshold &&
    typeof input.threshold === "string" &&
    input.threshold.includes("%")
  ) {
    targetRatio = Number(input.threshold.replace("%", "")) / 100;
  }

  if (
    // ratio as "30"
    input.threshold &&
    typeof input.threshold === "string" &&
    !input.threshold.includes("%")
  ) {
    targetRatio = Number(input.threshold) / 100;
  }

  // plain number threshold
  if (
    // ratio as "30"
    input.threshold &&
    typeof input.threshold === "number"
  ) {
    targetRatio = input.threshold / 100;
  }

  result.threshold = targetRatio;
  return result;
};

const getObserverCallbackFromOptions = (input = {}) => {
  const cb = (entries, observer) => {
    let entry = entries[0];

    if (entry.intersectionRatio === 0) {
      return;
    }


    // remove class
    if (input.removeClass) {
      entry.target.classList.remove(input.removeClass);
    }

    // add class
    if (input.class && entry.target) {
      entry.target.classList.add(input.class);
    }

    if (input.addClass && entry.target) {
      entry.target.classList.add(input.addClass);
    }

    if (input.animate) {
      let className = "animate__" + input.animate;
      entry.target.classList.remove("visibility-hidden");
      entry.target.classList.add(className);
    }

    //run cb ('cb')
    if (typeof input.cb === "function") {
      input.cb({ target: entry.target, entry });
    }

    // run CB ('callback')
    if (typeof input.callback === "function") {
      input.callback({ target: entry.target, entry });
    }
  };
  return cb;
};

export default {
  updated(el, { value: directiveData }) {
    if (utilities.isSSR()) {
      return;
    }
    // TODO
  },
  mounted(el, { value: directiveData }) {
    if (utilities.isSSR()) {
      return;
    }

    const observerOptions = getObserverConfigFromOptions(directiveData);
    const cb = getObserverCallbackFromOptions(directiveData);

    const observer = new IntersectionObserver(cb, observerOptions);
    observer.observe(el);

    el.saffronIntersectionObserver = observer;

    // this thing needs to be animated, so hide it right now
    if (directiveData.animate) {
      el.classList.add("visibility-hidden");
    }
  },
  beforeUnmount(el) {
    if (utilities.isSSR()) {
      return;
    }

    if (el.saffronIntersectionObserver) {
      el.saffronIntersectionObserver.disconnect();
    }
  },
};
